import React, { FC, useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'gatsby'

import styles from './styles.module.scss'

const SCROLL_MARGIN = 100

interface NavbarProps {
  title: string
  pages: string[]
}

// use boostrap classes navbar-brand and nav-link in combination with gatsby link
const CustomNavbar: FC<NavbarProps> = ({ title, pages }) => {
  const [isTransparent, setIsTransparent] = useState(true)

  useEffect(() => {
    const listener = () => setIsTransparent(window.scrollY < SCROLL_MARGIN)

    document.addEventListener('scroll', listener)
    return () => document.removeEventListener('scroll', listener)
  }, [isTransparent])

  return (
    <Navbar
      className={classNames(
        styles.smoothTransition,
        isTransparent ? '' : styles.white,
      )}
      fixed="top"
      collapseOnSelect
      expand="lg"
    >
      <Link className="navbar-brand" to="/">
        {title.toUpperCase()}
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {pages.map((name: string, index: number) => (
            <Link className="nav-link" key={index} to={`/${name}`}>
              {name.toUpperCase()}
            </Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export { CustomNavbar as Navbar }
