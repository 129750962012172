import { graphql, useStaticQuery } from 'gatsby'

export interface SiteMetadata {
  title: string
  subtitle: string
  author: {
    name: string
    summary: string
  }
  description: string
  siteUrl: string
}

type SiteMetadataQuery = { site: { siteMetadata: SiteMetadata } }

export const useSiteMetadata = (): SiteMetadata => {
  const queryResults = useStaticQuery<SiteMetadataQuery>(graphql`
    query {
      site {
        siteMetadata {
          title
          subtitle
          author {
            name
            summary
          }
          description
          siteUrl
        }
      }
    }
  `)
  return queryResults.site.siteMetadata
}
