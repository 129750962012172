import React, { FC } from 'react'

import { Navbar } from '../Navbar'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { rhythm } from '../../utils/typography'
import { SiteMetadata } from '../../hooks'

interface LayoutComponentProps {
  location: Location
  siteMetadata: SiteMetadata
}

export const LayoutComponent: FC<LayoutComponentProps> = ({
  location,
  siteMetadata,
  children,
}) => {
  return (
    <>
      <Navbar title={siteMetadata.title} pages={['about', 'events']} />
      <Header
        location={location}
        title={siteMetadata.title}
        subtitle={siteMetadata.subtitle}
      />
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}
