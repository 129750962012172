import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { SiteMetadata, useSiteMetadata } from '../../hooks'

interface SEOProps {
  pageTitle?: string
  pageDescription?: string
  isPost?: boolean
}

export const SEO: FC<SEOProps> = ({
  pageTitle = null,
  pageDescription = null,
  isPost = false,
}) => {
  const siteMetadata = useSiteMetadata()

  const title = buildTitle(pageTitle, siteMetadata)
  const description = buildDescription(pageDescription, siteMetadata)

  return (
    <Helmet
      htmlAttributes={{
        lang: 'de',
      }}
      title={title}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `author`,
          content: siteMetadata.author.name,
        },
        // open graph tags
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: isPost ? `article` : `website`,
        },
      ]}
    />
  )
}

const buildTitle = (title: string | null, siteMetadata: SiteMetadata) => {
  let titleFields = [siteMetadata.title, siteMetadata.subtitle]

  if (title) {
    titleFields = [title, ...titleFields]
  }

  return titleFields.join(' | ')
}

const buildDescription = (
  description: string | null,
  siteMetadata: SiteMetadata,
) => description || siteMetadata.description
