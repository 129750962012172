import React, { FC } from 'react'
import { useSiteMetadata } from '../../hooks'
import { LayoutComponent } from './component'

interface LayoutProps {
  location: Location
}

export const Layout: FC<LayoutProps> = ({ location, children }) => {
  const siteMetadata = useSiteMetadata()

  return (
    <LayoutComponent
      location={location}
      siteMetadata={siteMetadata}
      children={children}
    />
  )
}
