import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { HeaderComponent } from './component'

interface HeaderProps {
  location: any
  title: string
  subtitle: string
}

const query = graphql`
  query HeaderQuery {
    desktop: file(absolutePath: { regex: "/landing.jpg/" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export const Header: FC<HeaderProps> = ({ location, title, subtitle }) => {
  const data = useStaticQuery(query)
  const imageData = data.desktop.childImageSharp.fluid
  const isRoot = location.pathname === `/`

  return (
    <HeaderComponent
      isRoot={isRoot}
      title={title}
      subtitle={subtitle}
      backgroundImage={imageData}
    />
  )
}
