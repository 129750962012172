import React, { FC } from 'react'
import cn from 'classnames'
import BackgroundImage, { IFluidObject } from 'gatsby-background-image'

import * as styles from './styles.module.scss'

interface HeaderComponentProps {
  backgroundImage: IFluidObject
  isRoot?: boolean
  title: string
  subtitle?: string
}

export const HeaderComponent: FC<HeaderComponentProps> = ({
  backgroundImage,
  isRoot = true,
  title,
  subtitle,
}) => (
  <BackgroundImage
    className={cn(
      styles.verticalContainer,
      styles.navbarOffset,
      isRoot ? styles.root : styles.post,
    )}
    Tag="header"
    fluid={backgroundImage}
  >
    {isRoot ? <Heading title={title} subtitle={subtitle} /> : null}
  </BackgroundImage>
)

interface HeadingProps {
  title: string
  subtitle?: string
}

const Heading: FC<HeadingProps> = ({ title, subtitle }) => (
  <div className={styles.negativeTopMargin} >
    <h1 className={styles.responsiveFontSize}>{title}</h1>
    <h2 className={styles.verticalContainer}>{subtitle}</h2>
  </div>
)
