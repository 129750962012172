import React, { FC } from 'react'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../../hooks'

export const Footer: FC = () => {
  const { author } = useSiteMetadata()

  return (
    <footer>
      © {new Date().getFullYear()} {author.name}
      {' | '}
      <Link to={'/imprint'}>Impressum</Link>
      {' | '}
      <Link to={'/privacy'}>Datenschutz</Link>
    </footer>
  )
}
